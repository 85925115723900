<template>
  <div class="setting">
    <p class="setting-text small">{{ setting.text }}</p>
    <b-form-checkbox
      :class="{ 'custom-control-checked': setting.value }"
      class="check-button"
      size="lg"
      switch
      v-model="setting.value"
    />
  </div>
</template>

<script>
export default {
  name: "CookieSettingItem",
  props: ["setting"],
};
</script>
